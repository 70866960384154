import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, RACButton, RACCard } from '@rentacenter/racstrap';
import clsx from 'clsx';
import {
  usePriorityFieldCustomerState,
  usePriorityFieldCustomerActions,
} from '../../context/PriorityFieldVisit/PriorityFieldProvider';
import FieldSheet from '../FieldSheet/FieldSheet';
import {
  useFieldSheets,
  useFieldSheetsActions,
} from '../../context/FieldSheets/FieldSheetsProvider';
import { FULL_API_ERROR_MESSAGE } from '../../constants/constants';
import { ApiErrorModal } from '../../common/ApiError/ApiError';

const useStyles = makeStyles((theme: any) => ({
  footerRoot: {
    width: '100%',
    marginBottom: '0rem',
    display: 'block',
    position: 'fixed',
    bottom: '0',
    left: '0',
    zIndex: 1100,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(0),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0rem',
  },
  cardBody: {
    flex: '0 0 auto',
    padding: '1rem 1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.typography.pxToRem(4),
    padding: '1rem',
  },
  button: {
    height: theme.typography.pxToRem(43),
  },
}));

export const PriorityCustomerFooter = () => {
  const classes = useStyles();
  const { saveToXls, setSelectedCustomerIds } =
    usePriorityFieldCustomerActions();
  const { isCustomerSelected, selectedCustomerIds } =
    usePriorityFieldCustomerState();
  const [printFieldSheetClicked, setPrintFieldSheetClicked] =
    useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [isApiErrorOpen, setIsApiErrorOpen] = useState(false);
  const [isPrintFieldSheetPreview, setIsPrintFieldSheetPreview] =
    useState<boolean>(false);

  const { onFetchFieldSheets } = useFieldSheetsActions();

  const {
    fieldSheets,
    isLoading: isFieldSheetLoading,
    hasApiError: hasPrintFieldSheetApiError,
  } = useFieldSheets();

  useEffect(() => {
    if (hasPrintFieldSheetApiError) {
      setIsPrintFieldSheetPreview(false);
      setPrintFieldSheetClicked(false);
      setApiErrorMessage(FULL_API_ERROR_MESSAGE);
      setIsApiErrorOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPrintFieldSheetApiError]);

  useEffect(() => {
    const doFieldSheet = async () => {
      await onPrintFieldSheetConfirm('NONE'); // default to zero days and bypass modal !
    };
    if (printFieldSheetClicked) {
      doFieldSheet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printFieldSheetClicked]);

  const onPrintFieldSheetConfirm = async (selectedValue: string) => {
    setIsPrintFieldSheetPreview(true);
    await onFetchFieldSheets(selectedValue, selectedCustomerIds, false);
  };

  const togglePrintFieldSheetPreview = () => {
    setIsPrintFieldSheetPreview(!isPrintFieldSheetPreview);
    isPrintFieldSheetPreview && setSelectedCustomerIds([]);
    setPrintFieldSheetClicked(!printFieldSheetClicked);
  };

  return (
    <div className={clsx(classes.footerRoot)}>
      <RACCard className={classes.card}>
        <div className={clsx(classes.cardBody)}>
          <div className={clsx(classes.row)}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                classes={{ root: classes.buttonContainer }}
              >
                {isApiErrorOpen && (
                  <ApiErrorModal
                    open={isApiErrorOpen}
                    message={apiErrorMessage}
                    onClose={() => {
                      setApiErrorMessage('');
                      setIsApiErrorOpen(false);
                    }}
                  />
                )}
                {isPrintFieldSheetPreview && (
                  <FieldSheet
                    isLoading={isFieldSheetLoading}
                    onSave={() => {
                      setPrintFieldSheetClicked(false);
                      setIsPrintFieldSheetPreview(false);
                      setSelectedCustomerIds([]);
                    }}
                    fieldSheets={fieldSheets}
                    onClose={togglePrintFieldSheetPreview}
                  />
                )}
                <RACButton
                  className={clsx(classes.button)}
                  variant="contained"
                  disabled={!isCustomerSelected}
                  size="small"
                  key="primaryFieldVisitPrintFieldSheets"
                  color="primary"
                  onClick={() => setPrintFieldSheetClicked(true)}
                >
                  Print Field Sheet
                </RACButton>
                <RACButton
                  className={clsx(classes.button)}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    saveToXls();
                  }}
                >
                  Save to Excel
                </RACButton>
              </Grid>
            </Grid>
          </div>
        </div>
      </RACCard>
    </div>
  );
};
