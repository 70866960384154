/* eslint-disable sonarjs/cognitive-complexity */
import {
  makeStyles,
  RACBadge,
  RACBreadcrumb,
  RACBreadcrumbLinkType,
  Typography,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { ApiStateWrapper } from '../../common/ApiStateWrapper/ApiStateWrapper';
import { AppRoute } from '../../config/route-config';
import { DATE_FORMAT, getVerificationString } from '../../constants/constants';
import { useCustomerDetails } from '../../context/CustomerDetails/CustomerDetailsProvider';
import { PastDueCustomerResponse } from '../../domain/PastDueList/PastDueCustomerList';
import { formatDateString, formatMoney } from '../../utils/utils';
import { useStoreDetails } from '../../context/Store/StoreProvider';

import { useHistory } from 'react-router-dom';
export interface CustomerProps {
  customer?: PastDueCustomerResponse;
}

const useStyles = makeStyles((theme: any) => ({
  contentHeight: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
  },
  row: {
    display: 'flex',
    width: '100%',
  },
  breadcrumbContainer: {
    width: '100%',
    height: theme.typography.pxToRem(45),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  deInfoContainer: {
    right: 0,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: theme.typography.pxToRem(40),
  },
  deInfo: {
    color: '#2179FE',
  },
  deStatusDetail: {
    paddingTop: theme.typography.pxToRem(8),
  },
  approvalExpiryInfo: {
    color: '#5b6d83',
  },
}));

export const initialLinks = [
  {
    href: `${AppRoute.Index}`,
    label: 'Dashboard',
  },
  {
    href: `${AppRoute.PastDueList}`,
    label: 'Past Due List',
  },
];

export const initialLinksM = [
  {
    href: `${AppRoute.Dashboard}`,
    label: 'Dashboard',
  },
  {
    href: `${AppRoute.PastDueList}`,
    label: 'Past Due List',
  },
];

const getLinks = () => {
  if (window.location.pathname.includes('menu')) {
    return initialLinksM;
  }
  return initialLinks;
};
const getCustomerPath = (customerId: any) => {
  if (window.location.pathname.includes('menu')) {
    // return `/menu/customer/update/${customerId}/customer`;
    return `/customer/update/${customerId}/customer`;
  }
  return '/am/customer/' + customerId;
};

export const CustomerBreadcrumb = () => {
  const ownClasses = useStyles();
  const [links, setLinks] =
    React.useState<RACBreadcrumbLinkType[]>(initialLinks);
  const {
    customerDetails,
    loading,
    hasApiError,
    customerApprovalDetailsResp,
    customerApprovalLabel,
    customerVerificationLabel,
    getCustomerResponse,
  } = useCustomerDetails();

  const { customerVerificationOptimization } = useStoreDetails();
  const [showLevels, setShowLevels] = useState(false);
  const { firstName, lastName, customerId, verifiedDate } =
    customerDetails || {};
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!customerDetails) return;
    const links = getLinks();
    const label =
      firstName && lastName && customerId
        ? `${firstName || ''} ${lastName || ''} - ${customerId || ''}`
        : '';
    setLinks([
      ...links,
      {
        href: `${getCustomerPath(customerId)}`,
        label: label,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails]);
  const handleOnclick = () => {
    /* eslint-disable no-console */
    console.log('OnClick');
  };
  const customerDetailLoaded =
    loading !== undefined && !loading && !hasApiError;

  const approvalExpirationDate = formatDateString(
    customerApprovalDetailsResp?.customerApprovalDetails
      ?.approvalExpirationDate,
    DATE_FORMAT
  );
  const verificationExpirationDate = formatDateString(
    verifiedDate,
    DATE_FORMAT
  );
  const handleClick = (event: any, href: string) => {
    history.push(href);
  };
  return (
    <>
      <ApiStateWrapper
        loading={loading}
        hasApiError={hasApiError}
        successContent={<></>}
        classes={{
          loader: ownClasses.contentHeight,
          apiError: ownClasses.contentHeight,
        }}
      />
      {customerDetailLoaded && (
        <div className={classes.row}>
          <div className={clsx(classes.breadcrumbContainer)}>
            <RACBreadcrumb links={links} onClick={handleClick} />
            <div className={classes.deInfoContainer}>
              <div className={classes.deInfo}>
                <div className={classes.row}>
                  <Typography display="inline" variant="body1">
                    Approval Amount :{' '}
                  </Typography>
                </div>
                <div className={classes.row}>
                  <Typography display="inline" variant="body1">
                    {formatMoney(
                      customerApprovalDetailsResp?.customerApprovalDetails
                        ?.approvalAmount
                    )}
                  </Typography>
                </div>
              </div>

              <div className={classes.deInfo}>
                <div className={classes.row}>
                  <Typography display="inline" variant="body1">
                    Weekly Approval Amount :{' '}
                  </Typography>
                </div>
                <div className={classes.row}>
                  <Typography display="inline" variant="body1">
                    {formatMoney(
                      customerApprovalDetailsResp?.customerApprovalDetails
                        ?.weeklyApprovalAmount
                    )}
                  </Typography>
                </div>
              </div>
              <div className={classes.deStatusDetail}>
                <div className={classes.row}>
                  {customerVerificationOptimization ? (
                    <>
                      {getCustomerResponse &&
                      getCustomerResponse?.GetCustomer?.value &&
                      getCustomerResponse?.GetApproval?.value
                        ?.customerApprovalDetails &&
                      (getCustomerResponse?.GetApproval?.value
                        ?.customerApprovalDetails?.verifyCode == '1' ||
                        getCustomerResponse?.GetApproval?.value
                          ?.customerApprovalDetails?.verifyCode == '-1' ||
                        getCustomerResponse?.GetApproval?.value
                          ?.customerApprovalDetails?.verifyCode == '0' ||
                        getCustomerResponse?.GetApproval?.value
                          ?.customerApprovalDetails?.verifyCode == null) ? (
                        <>
                          <span
                            style={{
                              backgroundColor: '#bb37ae',
                              color: 'white',
                              borderRadius: '11px',
                              marginRight:
                                getCustomerResponse?.GetApproval?.value
                                  ?.customerApprovalDetails
                                  ?.verificationStatus == 'NOTREQUIRED'
                                  ? '-35px'
                                  : '13px',
                              float: 'left',
                              padding: '5px 10px',
                              fontSize: '0.75em',
                              fontWeight: 700,
                              lineHeight: 1,
                              textAlign: 'center',
                              whiteSpace: 'nowrap',
                              verticalAlign: 'baseline',
                            }}
                          >
                            {getCustomerResponse?.GetCustomer?.value
                              ?.salesLeadId &&
                            (getCustomerResponse?.GetApproval?.value
                              ?.customerApprovalDetails?.verifyCode == '-1' ||
                              getCustomerResponse?.GetApproval?.value
                                ?.customerApprovalDetails?.verifyCode == null)
                              ? 'Previous-Web'
                              : getCustomerResponse?.GetCustomer?.value
                                  ?.salesLeadId &&
                                (getCustomerResponse?.GetApproval?.value
                                  ?.customerApprovalDetails?.verifyCode ==
                                  '0' ||
                                  getCustomerResponse?.GetApproval?.value
                                    ?.customerApprovalDetails?.verifyCode ==
                                    '1')
                              ? 'New-Web'
                              : getCustomerResponse?.GetApproval?.value
                                  ?.customerApprovalDetails?.verifyCode ==
                                  '-1' ||
                                getCustomerResponse?.GetApproval?.value
                                  ?.customerApprovalDetails?.verifyCode == null
                              ? 'Previous'
                              : 'New'}
                          </span>
                        </>
                      ) : null}
                      {/* Customer type binding end */}

                      {/* Verification Level badge start*/}
                      {getCustomerResponse?.GetApproval?.value
                        ?.customerApprovalDetails?.verifyLevel &&
                      getCustomerResponse?.GetApproval?.value
                        ?.customerApprovalDetails?.verificationStatus ==
                        'COMPLETED' ? (
                        <span
                          style={{
                            backgroundColor: '#0d6efd',
                            color: 'white',
                            borderRadius: '11px',
                            marginRight: '-35px',
                            float: 'left',
                            padding: '5px 10px',
                            fontSize: '0.75em',
                            fontWeight: 700,
                            lineHeight: 1,
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            verticalAlign: 'baseline',
                          }}
                        >
                          {'Verified'}
                        </span>
                      ) : getCustomerResponse?.GetApproval?.value
                          ?.customerApprovalDetails?.verificationStatus ==
                        'PENDING' ? (
                        <span style={{ position: 'relative' }}>
                          <span
                            onMouseOver={() => {
                              setShowLevels(true);
                            }}
                            onMouseOut={() => {
                              setShowLevels(false);
                            }}
                            style={{
                              backgroundColor: '#e85c5c',
                              color: 'white',
                              borderRadius: '11px',
                              marginRight:
                                getCustomerResponse?.GetApproval?.value
                                  ?.customerApprovalDetails?.approvalStatus ==
                                  'A' ||
                                getCustomerResponse?.GetApproval?.value
                                  ?.customerApprovalDetails?.approvalStatus ==
                                  'M'
                                  ? '-30px'
                                  : '13px',
                              float: 'left',
                              padding: '5px 10px',
                              fontSize: '0.75em',
                              fontWeight: 700,
                              lineHeight: 1,
                              textAlign: 'center',
                              whiteSpace: 'nowrap',
                              verticalAlign: 'baseline',
                              cursor: 'pointer',
                            }}
                          >
                            {getCustomerResponse?.GetApproval?.value
                              ?.customerApprovalDetails?.verifyLevel &&
                            getCustomerResponse?.GetApproval?.value
                              ?.customerApprovalDetails?.verificationStatus !=
                              'COMPLETED'
                              ? `Level - ${getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel.replace(
                                  '-1',
                                  '3'
                                )} `
                              : 'Verified'}
                          </span>
                          {showLevels && (
                            <div
                              style={{
                                position: 'absolute',
                                bottom: '0px',
                                right:
                                  getCustomerResponse?.GetApproval?.value
                                    ?.customerApprovalDetails?.verifyLevel == 1
                                    ? '-200px'
                                    : getCustomerResponse?.GetApproval?.value
                                        ?.customerApprovalDetails
                                        ?.verifyLevel == 2
                                    ? '-265px'
                                    : '-302px',
                                transform: 'translateX(-50%)',
                                marginBottom: '20px',
                                backgroundColor: '#e9f1f6',
                                color: 'black',
                                padding: '5px',
                                borderRadius: '10px',
                                fontSize: '12px',
                                whiteSpace: 'nowrap',
                                zIndex: 2000,
                                fontWeight: 'bold',
                              }}
                            >
                              {getCustomerResponse?.GetApproval?.value
                                ?.customerApprovalDetails
                                ? getVerificationString(
                                    getCustomerResponse?.GetApproval?.value
                                      ?.customerApprovalDetails
                                  )
                                : ''}
                            </div>
                          )}
                        </span>
                      ) : getCustomerResponse?.GetApproval?.value
                          ?.customerApprovalDetails?.verifyLevel &&
                        getCustomerResponse?.GetApproval?.value
                          ?.customerApprovalDetails?.verificationStatus ==
                          'REVIEW PENDING' ? (
                        <span
                          style={{
                            backgroundColor: 'rgb(253 180 13)',
                            color: 'white',
                            borderRadius: '11px',
                            marginRight: '-30px',
                            float: 'left',
                            padding: '5px 10px',
                            fontSize: '0.75em',
                            fontWeight: 700,
                            lineHeight: 1,
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            verticalAlign: 'baseline',
                          }}
                        >
                          {'Review Pending'}
                        </span>
                      ) : null}

                      {/* Verification Level badge end line  */}
                    </>
                  ) : (
                    <RACBadge
                      TextValue={customerVerificationLabel.text}
                      backgroundColor={customerVerificationLabel.bgColor}
                      color="white"
                      OnclickNeed={false}
                      handleOnClick={handleOnclick}
                    />
                  )}
                </div>
                {!customerVerificationOptimization &&
                  customerVerificationLabel.showExpiryDate && (
                    <div className={classes.row}>
                      <div className={classes.approvalExpiryInfo}>
                        <Typography display="inline" variant="body1">
                          Expires on{' '}
                        </Typography>
                        <Typography display="inline" variant="body1">
                          {verificationExpirationDate}
                        </Typography>
                      </div>
                    </div>
                  )}
              </div>
              <div className={classes.deStatusDetail}>
                <div className={classes.row}>
                  <RACBadge
                    TextValue={customerApprovalLabel.text}
                    backgroundColor={customerApprovalLabel.bgColor}
                    color="white"
                    OnclickNeed={false}
                    handleOnClick={handleOnclick}
                  />
                </div>
                {customerApprovalLabel.showExpiryDate && (
                  <div className={classes.row}>
                    <div className={classes.approvalExpiryInfo}>
                      <Typography display="inline" variant="body1">
                        Expires on{' '}
                      </Typography>
                      <Typography display="inline" variant="body1">
                        {approvalExpirationDate}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
