/*eslint-disable no-console*/
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { PriorityCustomerDTO } from '../../domain/priorityFieldVisit/PriorityCustomerDTO';
import { getPriorityCustomers } from '../../api/Customer';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getFormattedDate } from '../../utils/utils';

export interface PriorityFieldCustomerState {
  priorityFieldVisitCustomerDetails: PriorityCustomerDTO[];
  loading?: boolean;
  exportToXls: any;
  isCustomerSelected: boolean;
  selectedCustomerIds: string[];
  hasApiError: boolean;
}

export interface PriorityFieldDispatchState {
  fetchPriorityCustomerDetails: (storeId: string) => void;
  saveToXls: () => void;
  onCustomerSelectChanged: (value: boolean) => void;
  setSelectedCustomerIds: (selectedCustomerIds: string[]) => void;
}

export const PriorityFieldCustomerStateContext =
  createContext<PriorityFieldCustomerState>({} as PriorityFieldCustomerState);

export const PriorityFieldDispatchContext =
  createContext<PriorityFieldDispatchState>({} as PriorityFieldDispatchState);

const headerExportIndex = [
  5, 6, 7, 0, 2, 15, 16, 17, 18, 19, 20, 3, 8, 9, 4, 21, 22, 23, 24, 25, 26, 27,
  28,
];
const exportIndex = [
  13, 14, 15, 0, 9, 23, 24, 25, 26, 27, 28, 11, 16, 17, 12, 29, 30, 31, 32, 33,
  34, 35, 36,
];
console.log(headerExportIndex);

export const PriorityFieldProvider = (props: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>();
  const [hasApiError, setHasApiError] = useState(false);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([]);
  const [isCustomerSelected, setIsCustomerSelected] = useState<boolean>(true);

  const exportToXls = useRef<HTMLTableElement | null>(null);
  const [
    priorityFieldVisitCustomerDetails,
    setPriorityFieldVisitCustomerDetails,
  ] = useState<PriorityCustomerDTO[]>([]);

  useEffect(() => {
    if (!selectedCustomerIds.length) {
      setIsCustomerSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerIds]);

  useEffect(() => {
    onClearSelectedOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClearSelectedOptions = () => {
    onCustomerSelectChanged(false);
  };

  const onCustomerSelectChanged = (selected: boolean) => {
    setIsCustomerSelected(selected);
  };

  const updateFlag = (
    priorityList: PriorityCustomerDTO[]
  ): PriorityCustomerDTO[] => {
    priorityList = priorityList.map((one) => {
      if (
        !one.epoOffer ||
        !one.epoOffer.trim() ||
        one.epoOffer.trim().toUpperCase().includes('NOT ELIGIBLE')
      ) {
        one = { ...one, epoOfferFlag: 'No' };
      } else {
        one = { ...one, epoOfferFlag: 'Yes' };
      }
      return one;
    });

    return priorityList;
  };

  const filterColumns = (): any[] => {
    const rows =
      exportToXls &&
      exportToXls.current &&
      exportToXls.current.querySelectorAll('tr');

    // Create an array to hold filtered data
    const filteredData: any[] = [];

    if (rows) {
      rows.forEach((row) => {
        const cells = row.querySelectorAll('tr, *');

        const rowData: any[] = [];

        if (filteredData.length == 0) {
          headerExportIndex.forEach((idx) => {
            rowData.push(cells[idx].textContent);
          });
        } else {
          exportIndex.forEach((idx) => {
            rowData.push(cells[idx].textContent);
          });
        }

        // Add the filtered row data to the filteredData array
        filteredData.push(rowData);
      });
    }
    return filteredData;
  };
  const saveToXls = () => {
    const filtered = filterColumns();
    if (exportToXls.current && filtered && filtered.length > 0) {
      const worksheet = XLSX.utils.aoa_to_sheet(filtered);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, getFormattedDate());

      const workbookBinary = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const mefilename = `rpt-FVPriority.xlsx`;
      saveAs(
        new Blob([workbookBinary], { type: 'application/octet-stream' }),
        mefilename
      );
    }
  };
  const fetchPriorityCustomerDetails = async (storeId: string) => {
    setHasApiError(false);
    setLoading(true);
    setPriorityFieldVisitCustomerDetails([]);

    await getPriorityCustomers(storeId)
      .then((response) => {
        if (response.responsecode == 'PV204') {
          setPriorityFieldVisitCustomerDetails([]);
        } else {
          let sorted = response?.priorityList?.sort(
            (first, second) =>
              Number(first.displayOrder) - Number(second.displayOrder)
          );
          sorted = updateFlag(sorted);
          setPriorityFieldVisitCustomerDetails(sorted);
        }
      })
      .then((error) => console.log(error))
      .catch((eee) => {
        if (eee?.response?.status === 400) {
          console.log(eee);
          setHasApiError(false);
        } else {
          console.log(eee);
          setHasApiError(true);
        }
      })

      .finally(() => setLoading(false));
  };
  return (
    <PriorityFieldCustomerStateContext.Provider
      value={{
        priorityFieldVisitCustomerDetails,
        loading,
        isCustomerSelected,
        selectedCustomerIds,
        hasApiError,
        exportToXls,
      }}
    >
      <PriorityFieldDispatchContext.Provider
        value={{
          fetchPriorityCustomerDetails,
          saveToXls,
          onCustomerSelectChanged,
          setSelectedCustomerIds,
        }}
      >
        {props.children}
      </PriorityFieldDispatchContext.Provider>
    </PriorityFieldCustomerStateContext.Provider>
  );
};

export const usePriorityFieldCustomerState = () =>
  useContext(PriorityFieldCustomerStateContext);

export const usePriorityFieldCustomerActions = () =>
  useContext(PriorityFieldDispatchContext);
