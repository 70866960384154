import {
  RACModal,
  Typography,
  RACButton,
  makeStyles,
  RACSelect,
  TextField,
} from '@rentacenter/racstrap';

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserStateContext } from '../../../context/user/user-contexts';
import { logActivity } from '../../../api/Customer';
import { ReferenceKeys } from '../../../types/types';
import { mapReferenceResponse, pipe } from '../../../utils/utils';
import { ActivityPayload, CustomerLocationState } from '../../../types/types';
import {
  WORKED_HISTORY_ACTIVITY_TYPE_CODES,
  API_ERROR_MESSAGE,
  SELECT_ONE_OPTION,
  CACHED_KEYS,
  WORKED_HISTORY_CALL_RESULT_CODES,
} from '../../../constants/constants';
import { getSelectedStore } from '../../../utils/utils';
import { getReference } from '../../../api/reference';
import { useCustomerDetails } from '../../../context/CustomerDetails/CustomerDetailsProvider';
import { useCustomerPaymentSummary } from '../../../context/CustomerPaymentSummary/CustomerPaymentSummaryProvider';
import { Option } from '../../../types/types';
// import { orderByDescField } from '../../../context/PastDueListSearchCriteria/PastDueListSearchCriteriaProvider';

export interface ModalProps {
  onClose: (onclose: boolean) => void;
  onSaveSuccess: () => void;
  onError: () => void;
}

export const FIELD_VISIT_NOTES_MAX_LENGTH = 256;

export interface FieldVisitModalContentProps {
  callResultOptions: Option[];
  apiError: boolean;
  callResult: string;
  note: string;
  isLoading: boolean;

  setCallResult: (result: string) => void;
  setNote: (note: string) => void;
}

const addSelectOneOption = (arrayWithOptions: any[]) => {
  if (!arrayWithOptions) {
    return [
      {
        label: SELECT_ONE_OPTION,
        value: '',
      },
    ];
  }

  return [
    {
      label: SELECT_ONE_OPTION,
      value: '',
    },
    ...arrayWithOptions,
  ];
};

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    marginBottom: '2rem',
  },
  dialogRoot: {
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialog-paperWidthXs': {
      maxWidth: theme.typography.pxToRem(500),
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
    '& .MuiTypography-h6': {
      fontFamily: 'OpenSans-semiBold',
      lineHeight: theme.typography.pxToRem(20),
    },
  },
  notesLabel: {
    color: '#212529',
    padding: '0px 0px 15px',
  },
  fieldVisitTextContent: {
    borderRadius: '5px',
    width: theme.typography.pxToRem(450),
  },
  callResultWrapper: {
    width: '75%',
  },
  callResultOptionsPaper: {
    maxHeight: theme.typography.pxToRem(150),
  },
  notesCharacterCount: {
    marginTop: theme.typography.pxToRem(5),
  },
}));

const FieldVisitModalContent = ({
  callResultOptions,
  apiError,
  callResult,
  note,
  isLoading,

  setCallResult,
  setNote,
}: FieldVisitModalContentProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.callResultWrapper}>
        <RACSelect
          classes={{ paper: classes.callResultOptionsPaper }}
          inputLabel="Field Visit Result"
          isDisabled={isLoading}
          defaultValue={callResult}
          loading={isLoading && !callResult.length}
          options={callResultOptions}
          onChange={(e: React.ChangeEvent<{ value: any }>) =>
            setCallResult(e.target.value)
          }
          {...(apiError && {
            errorMessage: API_ERROR_MESSAGE,
          })}
        />
      </div>
      <Typography variant="h6" className={classes.notesLabel}>
        Notes
      </Typography>
      <TextField
        className={classes.fieldVisitTextContent}
        multiline
        autoFocus
        variant="outlined"
        maxRows={3}
        minRows={3}
        value={note}
        onChange={(e) => {
          if (note.length >= FIELD_VISIT_NOTES_MAX_LENGTH) {
            const trimmedNote = e.target.value?.substring(
              0,
              FIELD_VISIT_NOTES_MAX_LENGTH
            );
            setNote(trimmedNote);
          } else {
            setNote(e.target.value);
          }
        }}
      />
      <span className={classes.notesCharacterCount}>
        ({FIELD_VISIT_NOTES_MAX_LENGTH}/{note.length})
      </span>
    </>
  );
};

function randomize(array: any) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const FieldVisitModal = ({
  onClose,
  onSaveSuccess,
  onError,
}: ModalProps) => {
  const classes = useStyles();
  const location = useLocation<CustomerLocationState>();
  const [note, setNote] = useState('');
  const { user } = useUserStateContext();
  const [pending, setPending] = useState(false);
  const [fieldVisitResultOptions, setFieldVisitResultOptions] = useState<
    Option[]
  >([]);
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [fieldVisitResult, setFieldVisitResult] = useState('');

  const { customerDetails } = useCustomerDetails();
  const { customerPaymentSummary } = useCustomerPaymentSummary();

  const handleSave = () => {
    const now = new Date().toISOString();
    const customerId =
      customerDetails?.customerId ||
      location?.state?.customer?.customerId ||
      location?.pathname?.split('/')[3];

    const { daysPastDue } = location?.state?.customer || {};

    const payload: ActivityPayload = {
      storeNumber: getSelectedStore(),
      notes: note,
      daysPastDue:
        customerPaymentSummary?.pastDueSummary?.daysPastDue?.toString() ||
        daysPastDue?.toString(),
      activityDate: now,
      callResultRefCode: WORKED_HISTORY_CALL_RESULT_CODES.FIELD_VISIT,
      acctActivityRefCode: WORKED_HISTORY_ACTIVITY_TYPE_CODES.CALL_CUSTOMER,
      //zzz, add fieldvisit result
      fieldVisitResultRefCode: fieldVisitResult,
      customerId: Number(customerId),
      coWorkerId: user?.employeeId || ' ',
      callTime: now,
    };
    setPending(true);
    logActivity(payload)
      .then(() => onSaveSuccess())
      .catch(() => onError())
      .finally(() => setPending(false));
  };
  useEffect(() => {
    setIsLoading(true);
    getReference(
      [ReferenceKeys.FIELD_VISIT_RESULT],
      CACHED_KEYS.FIELDVISITRESULT_CACHED_KEY
    )
      .then((response: any) =>
        pipe(
          randomize,
          mapReferenceResponse,
          addSelectOneOption,
          setFieldVisitResultOptions
        )(response?.references[0]?.referenceDetails)
      )
      .catch(() => {
        setApiError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <RACModal
      isOpen
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
      }}
      maxWidth="xs"
      title="Field Visit"
      content={
        <FieldVisitModalContent
          callResultOptions={fieldVisitResultOptions}
          apiError={apiError}
          isLoading={isLoading}
          callResult={fieldVisitResult}
          note={note}
          setCallResult={setFieldVisitResult}
          setNote={setNote}
        />
      }
      onClose={() => onClose(false)}
      buttons={
        <>
          <RACButton
            variant="outlined"
            color="secondary"
            onClick={() => onClose(false)}
            disabled={pending}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!fieldVisitResult.length || isLoading}
            loading={pending}
          >
            Save
          </RACButton>
        </>
      }
    />
  );
};
