/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@rentacenter/racstrap';
import {
  usePriorityFieldCustomerActions,
  usePriorityFieldCustomerState,
} from '../../context//PriorityFieldVisit/PriorityFieldProvider';
import { Footer } from '../../common/Footer/Footer';
import { ApiStateWrapper } from '../../common/ApiStateWrapper/ApiStateWrapper';
import { PriorityCustomerFooter } from './PriorityCustomerFooter';

import { PriorityFieldVisitCustomers } from './PriorityFieldVisitCustomers';
import { getStoreDetailByNumber } from '../../api/store';
import { StoreDetailResponse } from '../../types/types';
export const useStyles = makeStyles(() => ({
  contentHeight: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
  },
}));

export const PriorityFieldVisit = () => {
  const ownClasses = useStyles();
  const { loading, hasApiError } = usePriorityFieldCustomerState();
  const { fetchPriorityCustomerDetails } = usePriorityFieldCustomerActions();

  useEffect(() => {
    async function fetchData() {
      let storeId = '99999';
      const storeNumber = window.sessionStorage.getItem('storeNumber');

      if (storeNumber) {
        const details: StoreDetailResponse = await getStoreDetailByNumber(
          storeNumber
        );
        details.response.forEach((oneResp) => {
          if (oneResp?.storeNumber == storeNumber) {
            storeId = oneResp?.storeId;
          }
        });
      }
      fetchPriorityCustomerDetails(storeId);
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerDetailLoaded =
    loading !== undefined && !loading && !hasApiError;

  return (
    <>
      <ApiStateWrapper
        loading={loading}
        hasApiError={hasApiError}
        successContent={<></>}
        classes={{
          loader: ownClasses.contentHeight,
          apiError: ownClasses.contentHeight,
        }}
      />
      {customerDetailLoaded && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PriorityFieldVisitCustomers />
          </Grid>

          <Footer>
            <PriorityCustomerFooter />
          </Footer>
        </>
      )}
      {(hasApiError || loading) && <Footer>Not loaded .... </Footer>}
    </>
  );
};
