/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */

import {
  RACModal,
  RACButton,
  makeStyles,
  RACCOLOR,
  RACSelect,
  TextField,
  // RACDatePicker,
  RACTimePicker,
  Typography,
  Grid,
  RACModalCard,
} from '@rentacenter/racstrap';

import React, { useEffect, useState, useContext } from 'react';
import { orderByDisplaySeqField } from '../../../context/PastDueListSearchCriteria/PastDueListSearchCriteriaProvider';
import { getReference } from '../../../api/reference';
import {
  AgreementPayloadFormation,
  Agreements,
  Club,
  ClubAdjustmentsPayload,
  ReferenceKeys,
} from '../../../types/types';
import {
  getSelectedStore,
  mapReferenceResponse,
  pipe,
} from '../../../utils/utils';
import { API_ERROR_MESSAGE, CACHED_KEYS } from '../../../constants/constants';
import { Option } from '../../../types/types';
import clsx from 'clsx';
import { AppRoute } from '../../../config/route-config';
import { useUserStateContext } from '../../../context/user/user-contexts';
import {
  TakeCommitmentInput,
  CustomerLocationState,
} from '../../../types/types';
import { useLocation, useHistory } from 'react-router-dom';
import {
  takeCommitment,
  getCustomerAgreements,
  calculatePaymentModule,
} from '../../../api/Customer';
import { useCustomerPaymentSummary } from '../../../context/CustomerPaymentSummary/CustomerPaymentSummaryProvider';
import { CommitmentStateContext } from '../../../context/CommitmentProvider/CommitmentProvider';
import { CommitmentStateDispatchContext } from '../../../context/CommitmentProvider/CommitmentProvider';
import { useCustomerDetails } from '../../../context/CustomerDetails/CustomerDetailsProvider';
import { addSelectOneOption } from '../ContactInformation/LogActivityModal';
// import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onError: () => void;
}
const SCALE_CSS = 'scale(1) !important';
export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'left',
    height: theme.typography.pxToRem(400),
  },
  dialogRoot: {
    '& .MuiDialogContent-root': {
      padding: '1rem',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: theme.typography.pxToRem(800),
      maxHeight: theme.typography.pxToRem(455),
    },
    '& .MuiTypography-h4': {
      fontSize: '20px !important',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: theme.typography.pxToRem(10),
    },
    '& .MuiInputBase-multiline': {
      margin: '8px 0px 0px 0px !important',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'OpenSans-regular',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  notesLabel: {
    color: theme.palette.text.primary,
    transform: SCALE_CSS,
    ...theme.typography.body1,
    position: 'relative',
    display: 'block',
  },
  amountLabel: {
    color: theme.palette.text.primary,
    transform: SCALE_CSS,
    ...theme.typography.body1,
  },
  dialogActions: {
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
  },
  notesWrapper: {
    display: 'inline-flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.typography.pxToRem(24),
    marginBotton: theme.typography.pxToRem(16),
  },
  modalColumn: {
    width: '25%',
    paddingRight: theme.typography.pxToRem(12),
  },
  row: {
    display: 'flex',
  },
  topBottomLeftBorderRadius: {
    borderTopLeftRadius: `${theme.typography.pxToRem(9)} !important`,
    borderBottomLeftRadius: `${theme.typography.pxToRem(9)} !important`,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  topBottomRightBorderRadius: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: `${theme.typography.pxToRem(9)} !important`,
    borderTopRightRadius: `${theme.typography.pxToRem(9)} !important`,
  },
  amountValue: {
    padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(
      12
    )} ${theme.typography.pxToRem(6)} 0`,
    backgroundColor: RACCOLOR.ICEWIND_DALE,
    // Replace with SATIN_WHITE
    border: `${theme.typography.pxToRem(1)} solid ${RACCOLOR.ICEWIND_DALE}`,
    lineHeight: theme.typography.pxToRem(18),
    width: '90%',
    marginLeft: theme.typography.pxToRem(-1),
  },
  amountWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  amountValueWrapper: {
    marginTop: theme.typography.pxToRem(3),
    width: '100%',
    display: 'inline-flex',
  },
  amount$Value: {
    width: '10%',
    padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(12)}`,
    backgroundColor: RACCOLOR.ICEWIND_DALE,
    // Replace with SATIN_WHITE
    border: `${theme.typography.pxToRem(1)} solid ${RACCOLOR.ICEWIND_DALE}`,
    lineHeight: theme.typography.pxToRem(18),
  },
  textcenter: {
    textAlign: 'center',
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  mt3: {
    marginTop: theme.typography.pxToRem(16),
  },
  RACPOPMsg: {
    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 0,
  },
  modalfooter: {
    borderTop: '0px solid transparent',
  },
  mr15px: {
    marginRight: '15px',
  },
  alertLoader: {
    width: '30px !important',
    height: '30px !important',
    marginTop: '10px',
  },
  datePicker: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  labelStyle: {
    color: '#111111',
    position: 'relative',
    fontSize: '0.875rem',
    transform: SCALE_CSS,
    fontFamily: 'OpenSans-semibold',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0',
  },
}));

interface TakeCommitmentModalContentProps {
  validateForm: (state: boolean) => void;
  saveClicked: boolean;
  onSave: () => void;
  onError: () => void;
  setLoader: (val: boolean) => void;
}
const NOTES_MAX_LENGTH = 150;
const TakeCommitmentModalContent = ({
  validateForm,
  saveClicked,
  onSave,
  onError,
  setLoader,
}: TakeCommitmentModalContentProps) => {
  const classes = useStyles();
  const {
    selectedStoreDueSummary,
    agreementsSummary,
    clubSummary,
    customerPaymentSummary,
  } = useCustomerPaymentSummary();
  const {
    setChoosenCommitmentType,
    setCommitmentNotes,
    setCommitmentDate,
    setCommitmentTime,
  } = useContext(CommitmentStateDispatchContext);
  const [commitmentType, setCommitmentType] = useState('');
  const [commitmentTypeOption, setCommitmentTypeOption] = useState<Option[]>(
    []
  );
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [note, setNote] = useState('');
  const [amount, setAmount] = useState('');
  const [agreementInfo, setAgreementInfo] = useState<Agreements[]>();
  const [clubInfo, setClubInfo] = useState<Club>();
  const [commitmentAmount, setCommitmentAmount] = useState('');
  const [commitmentOpenFlag, setCommitmentOpenFlag] = useState(false);

  const dateFormat = 'yyyy-MM-dd';
  useEffect(() => {
    setIsLoading(true);
    setCommitmentNotes('');
    getReference(
      [ReferenceKeys.COMMITMENT_TYPE],
      CACHED_KEYS.TAKE_COMMITMENT_CACHED_KEY
    )
      .then((response: any) =>
        pipe(
          orderByDisplaySeqField,
          mapReferenceResponse,
          addSelectOneOption,
          setCommitmentTypeOption
        )(response?.references[0]?.referenceDetails)
      )
      .catch(() => {
        setApiError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.state?.commitmentDetails) {
      setAmount(location?.state?.commitmentDetails?.amount);
    } else {
      setAmount(selectedStoreDueSummary?.totalAmountDue || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCustomerPaymentSummary]);

  useEffect(() => {
    if (location?.state?.commitmentDetails) {
      setAgreementInfo(location?.state?.commitmentDetails?.agreements);
    } else {
      setAgreementInfo(agreementsSummary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementsSummary]);

  useEffect(() => {
    if (location?.state?.commitmentDetails) {
      setClubInfo(location?.state?.commitmentDetails?.club);
    } else {
      setClubInfo(clubSummary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubSummary]);

  useEffect(() => {
    validateForm(
      time === '' || date === '' || commitmentType === '' || note === ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveClicked, time, date, commitmentType, note]);

  useEffect(() => {
    if (saveClicked) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveClicked]);

  const { user } = useUserStateContext();
  const location = useLocation<CustomerLocationState>();

  useEffect(() => {
    if (location?.state?.commitmentDetails) {
      setChoosenCommitmentType(
        location?.state?.commitmentDetails?.commitmentType
      );
      setTime(location?.state?.commitmentDetails?.commitmentTime);
      setCommitmentTime(location?.state?.commitmentDetails?.commitmentTime);
      setDate(location?.state?.commitmentDetails?.commitmentDate);
      setCommitmentDate(location?.state?.commitmentDetails?.commitmentDate);
      setNote(location?.state?.commitmentDetails?.notes);
      setCommitmentNotes(location?.state?.commitmentDetails?.notes);
      setCommitmentType(location?.state?.commitmentDetails?.commitmentType);
      setAmount(location?.state?.commitmentDetails?.amount);
      setAgreementInfo(location?.state?.commitmentDetails?.agreements);
      setClubInfo(location?.state?.commitmentDetails?.club);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const handleSave = async () => {
    const customerId =
      location?.state?.customer?.customerId ||
      location?.pathname?.split('/')[3];
    const payload: TakeCommitmentInput = {
      storeNumber: getSelectedStore(),
      notes: note,
      customerId: customerId,
      coWorkerId: user?.employeeId || ' ',
      commitmentType: commitmentType,
      amount: amount,
      commitmentDate: `${date}T${time}`,
      agreements: agreementInfo,
      club: clubInfo,
    };

    const validateResult: any = validatePayloadWithSum(payload);

    let updatedPayload: any = validateResult.updatePayload;
    if (validateResult.notExist) {
      const custAgrs = await fetchCustomerAgreements(customerId);

      if (!custAgrs) return;
      updatedPayload = validatePayload(custAgrs, payload);
    }

    takeCommitment(updatedPayload)
      .then(() => onSave())
      .catch(() => onError());
  };
  const fetchCustomerAgreements = async (customerId: string) => {
    try {
      const response: any = await getCustomerAgreements(customerId);
      return response.agreement;
    } catch (e) {
      onError();
    }
  };

  const validatePayloadWithSum = (pload: TakeCommitmentInput) => {
    const sum: any = customerPaymentSummary || [];
    if (!pload.agreements) return { updatePayload: pload, notExist: false };
    let notExist = false;

    if (!sum || !sum.agreementsSummary) {
      return { updatePayload: pload, notExist: false };
    }
    const updatedAgreements = pload.agreements.filter((item: any) => {
      const findItem = sum.agreementsSummary.find(
        (element: any) =>
          element.agreementId == item.agreementId &&
          (element.status == 'ACTIVE' || element.status == 'PEND')
      );
      const existed = sum.agreementsSummary.find(
        (element: any) => element.agreementId == item.agreementId
      );
      if (!existed) notExist = true;
      return findItem;
    });
    if (updatedAgreements.length == pload.agreements.length)
      return { updatePayload: pload, notExist: false };
    const amountOrig: number = pload.agreements.reduce(
      (accumulator: number, item: any) => accumulator + Number(item.amount),
      0
    );
    const amountUpdate: number = updatedAgreements.reduce(
      (accumulator: number, item: any) => accumulator + Number(item.amount),
      0
    );

    const updatedAmount: number =
      Number(pload.amount).valueOf() - (amountOrig - amountUpdate);

    pload.agreements = updatedAgreements;
    pload.amount = updatedAmount.toFixed(2);
    return { updatePayload: pload, notExist: notExist };
  };

  const validatePayload = (customerAgrs: any, pload: TakeCommitmentInput) => {
    if (!pload.agreements) return pload;

    const updatedAgreements = pload.agreements.filter((item: any) => {
      return (
        !customerAgrs.find(
          (element: any) => element.agreementId == item.agreementId
        ) ||
        customerAgrs.find(
          (element: any) =>
            element.agreementId == item.agreementId &&
            (element.agreementRefCode == 'ACTIVE' ||
              element.agreementRefCode == 'PEND')
        )
      );
    });
    if (updatedAgreements.length == pload.agreements.length) return pload;
    const amountOrig: number = pload.agreements.reduce(
      (accumulator: number, item: any) => accumulator + Number(item.amount),
      0
    );
    const amountUpdate: number = updatedAgreements.reduce(
      (accumulator: number, item: any) => accumulator + Number(item.amount),
      0
    );

    const updatedAmount: number =
      Number(pload.amount).valueOf() - (amountOrig - amountUpdate);

    pload.agreements = updatedAgreements;
    pload.amount = updatedAmount.toFixed(2);
    return pload;
  };

  const commitmentOpenPopup = () => {
    return (
      <Grid
        item
        id="returnPendingAlertPopUp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="ReturnPendingAlertPopUp"
        aria-hidden="true"
        data-testid="popupGrid"
      >
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant={'body2'} className={classes.RACPOPMsg}>
            {
              'The Commitment date has expired a late fee will be added based on current date'
            }
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
        >
          <RACButton
            data-testid="noBtn"
            className={` ${classes.mr15px}`}
            color="primary"
            variant="outlined"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => {
              setCommitmentOpenFlag(false);
              setDate('');
              setCommitmentDate('');
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            data-testid="yesBtn"
            className={`${classes.mr15px}`}
            color="primary"
            variant="outlined"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => {
              setCommitmentOpenFlag(false);
              setAmount(commitmentAmount);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const commitmentDateOnclick = async (val: string) => {
    const agreementPayloadFormation: AgreementPayloadFormation[] = [];
    const clubAdjustmentsPayload: ClubAdjustmentsPayload[] = [];
    const moduleRequest = location?.state?.commitmentDetails?.moduleRequest;
    if (val) {
      agreementsSummary &&
        agreementsSummary?.length > 0 &&
        agreementsSummary?.forEach((val: Agreements) => {
          agreementPayloadFormation.push({ agreementId: val.agreementId });
        });
      clubSummary
        ? clubAdjustmentsPayload.push({
            customerClubId: String(clubSummary?.clubId),
          })
        : undefined;
      let modulePayload = {
        customerId:
          location?.state?.customer?.customerId ||
          location?.pathname?.split('/')[3],
        paymentOrigin: 'S',
        agreementAmountDue:
          agreementPayloadFormation?.length > 0
            ? agreementPayloadFormation
            : undefined,
        commitmentDate: val,
        clubAmountDue:
          clubAdjustmentsPayload.length > 0
            ? clubAdjustmentsPayload
            : undefined,
      };
      if (moduleRequest) {
        modulePayload = { ...moduleRequest, commitmentDate: val };
      }
      try {
        setLoader(true);
        let commonModuleResponse = await calculatePaymentModule(modulePayload);
        setLoader(false);
        if (commonModuleResponse) {
          const storeNumber = getSelectedStore();
          commonModuleResponse = commonModuleResponse.paymentDetails.filter(
            (agr: any) => agr.storeNumber == storeNumber
          );

          const agrTotalAmountDue = calculateAmount(
            commonModuleResponse[0]?.agreementPaymentDetails,
            'totalAmount'
          );
          const agrTotalTax = calculateAmount(
            commonModuleResponse[0]?.agreementPaymentDetails,
            'totalTax'
          );
          const clubTotalAmountDue = calculateAmount(
            commonModuleResponse[0]?.clubPaymentDetails,
            'totalAmount'
          );
          const clubTotalTax = calculateAmount(
            commonModuleResponse[0]?.clubPaymentDetails,
            'totalTax'
          );

          const totalAmountIncludingTax: string = Number(
            agrTotalAmountDue + agrTotalTax + clubTotalAmountDue + clubTotalTax
          ).toFixed(2);

          if (Number(totalAmountIncludingTax) != Number(amount)) {
            setCommitmentOpenFlag(true);
            setCommitmentAmount(totalAmountIncludingTax);
          }
        }
      } catch (error) {
        setLoader(false);
      }
    }
  };
  const format = (date: any) => {
    // const options: any = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(date); // `en-CA` ensures the format is `YYYY-MM-DD`
  };

  const calculateAmount = (details: any[], val: string) => {
    return details?.length > 0
      ? details?.reduce(
          (accum: number, item: any) => accum + Number(item[val]),
          0
        )
      : 0;
  };

  return (
    <>
      <div className={classes.row}>
        <div className={classes.modalColumn}>
          <RACSelect
            inputLabel="Commitment Type"
            defaultValue={commitmentType}
            options={commitmentTypeOption}
            loading={isLoading}
            onChange={(e: React.ChangeEvent<{ value: any }>) => {
              setCommitmentType(e.target.value);
              setChoosenCommitmentType(e.target.value);
            }}
            {...(apiError && {
              errorMessage: API_ERROR_MESSAGE,
            })}
          />
        </div>
        <div className={classes.modalColumn}>
          {/* <RACDatePicker
            label="Date"
            inputProps={{ min: format(new Date(), dateFormat) }}
            value={date}
            onChange={(e: any) => {
              setDate(e);
              setCommitmentDate(e);
              commitmentDateOnclick(e);
            }}
            required={true}
            name="commitmentDate"
          />
        </div> */}
          <label className={classes.labelStyle}>
            Date <span style={{ color: 'red' }}>*</span>
          </label>
          <DatePicker
            value={date}
            onChange={(e: any) => {
              if (e) {
                const formattedDate = e.toLocaleDateString('en-CA', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                });
                setDate(formattedDate);
                setCommitmentDate(formattedDate);
                commitmentDateOnclick(formattedDate);
              }
            }}
            dateFormat={dateFormat}
            minDate={format(new Date())}
            placeholderText="MM/DD/YYYY"
            showIcon
            shouldCloseOnSelect={true}
            yearDropdownItemNumber={100}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            dropdownMode="select"
            toggleCalendarOnIconClick
            onFocus={(e: any) => e.target.blur()}
            className={classes.datePicker}
          />
        </div>
        <div className={classes.modalColumn}>
          <RACTimePicker
            onChange={(e: any) => {
              setTime(e);
              setCommitmentTime(e);
            }}
            label="Time"
            name="commitmentTime"
            value={time}
          />
        </div>
        <div className={classes.modalColumn}>
          <label className={classes.amountLabel}>Amount</label>
          <div className={classes.amountValueWrapper}>
            <Typography
              display="inline"
              variant="body2"
              className={clsx(
                classes.amount$Value,
                classes.topBottomLeftBorderRadius
              )}
            >
              $
            </Typography>
            <Typography
              display="inline"
              variant="body2"
              className={clsx(
                classes.amountValue,
                classes.topBottomRightBorderRadius
              )}
            >
              {amount}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.notesWrapper}>
        <label className={classes.notesLabel}>Notes</label>
        <TextField
          multiline
          variant="outlined"
          value={note}
          key="logActivityNote"
          maxRows={4}
          minRows={47}
          onChange={(e: any) => {
            if (e.target.value.length > NOTES_MAX_LENGTH) {
              return;
            } else {
              setNote(e.target.value);
              setCommitmentNotes(e.target.value);
            }
          }}
        />
        <span>
          ({NOTES_MAX_LENGTH}/{note.length})
        </span>
      </div>
      <Grid>
        <RACModalCard
          isOpen={commitmentOpenFlag}
          maxWidth="xs"
          borderRadius={'15px !important'}
        >
          {commitmentOpenPopup()}
        </RACModalCard>
      </Grid>
    </>
  );
};

export const TakeCommitmentModal = ({
  open,
  onSave,
  onClose,
  onError,
}: ModalProps) => {
  const classes = useStyles();
  const [isFormInvalid, setIsFormInvalid] = useState(true);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { customerDetails } = useCustomerDetails();
  const { customerId } = customerDetails || {};
  const {
    choosenCommitmentType,
    commitmentNotes,
    commitmentTime,
    commitmentDate,
  } = useContext(CommitmentStateContext);
  const { setCommitmentDate } = useContext(CommitmentStateDispatchContext);
  const history = useHistory();
  const handleSaveClick = () => {
    setIsSaveClicked(true);
    setLoading(true);
  };
  const handleCalculatePayClick = () => {
    history.push({
      pathname: `${AppRoute.TakePayment}/${customerId}/0`,
      search: `?origin=am-customer`,
      state: {
        commitmentData: {
          commitmentType: choosenCommitmentType,
          commitmentDate: commitmentDate,
          commitmentTime: commitmentTime,
          commitmentNotes: commitmentNotes,
        },
      },
    });
  };

  return (
    <>
      <RACModal
        isOpen={open}
        titleVariant="h4"
        classes={{
          dialogContent: classes.dialogContent,
          dialog: classes.dialogRoot,
          dialogActions: classes.dialogActions,
        }}
        maxWidth="sm"
        title="Take a Commitment"
        content={
          <TakeCommitmentModalContent
            validateForm={setIsFormInvalid}
            saveClicked={isSaveClicked}
            onSave={onSave}
            onError={onError}
            setLoader={(val) => {
              setLoader(val);
            }}
          />
        }
        onClose={() => {
          setCommitmentDate('');
          onClose();
        }}
        buttons={
          <>
            <RACButton
              variant="outlined"
              color="secondary"
              onClick={() => {
                setCommitmentDate('');
                onClose();
              }}
              disabled={loading}
            >
              Cancel
            </RACButton>
            <span
              title={
                commitmentDate == ''
                  ? 'Select the Date to Calculate Payment'
                  : ''
              }
            >
              <RACButton
                variant="contained"
                color="primary"
                onClick={handleCalculatePayClick}
                loading={loader}
                disabled={
                  choosenCommitmentType == 'RT' ||
                  commitmentDate == '' ||
                  loader
                    ? true
                    : false
                }
              >
                Calculate Payment
              </RACButton>
            </span>
            <RACButton
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
              disabled={isFormInvalid || loading || loader}
              loading={loading}
            >
              Save
            </RACButton>
          </>
        }
      />
    </>
  );
};
